.App {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #282c34;
  }
  
  .App-header {
    color: white;
  }
  
  .App-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #61dafb;
    color: #282c34;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .App-link:hover {
    background-color: #21a1f1;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input {
    padding: 10px;
    width: 300px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #61dafb;
    color: #282c34;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #21a1f1;
  }
  